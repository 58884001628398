
































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import { getCustomFieldByKey } from '~/helpers/utils';

export type InspirationalTile = {
  id: number;
  urls: [
    {
      media_path: string;
      media_file_name: string;
      url: string;
    }
  ];
  custom_fields: [
    {
      key: 'title';
      value_string: string;
    },
    {
      key: 'text';
      value_string: string;
    },
    {
      key: 'button_text';
      value_string: string;
    }
  ];
};

export default defineComponent({
  name: 'InspirationalTile',
  props: {
    tile: {
      type: Object as PropType<InspirationalTile>,
      default: () => ({
        id: 0,
        urls: [
          {
            media_path: '',
            media_file_name: '',
            url: ''
          }
        ],
        custom_fields: () => []
      })
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const imageWidth = computed(() => (isDesktop.value ? 421 : 238));
    const imageHeight = computed(() => (isDesktop.value ? 316 : 238));

    const media_path = props.tile.urls?.[0]?.media_path;
    const media_file_name = props.tile.urls?.[0]?.media_file_name;

    const linkData = computed(() => props.tile.urls?.[0]);

    const getField = (field: string) => {
      return getCustomFieldByKey(props.tile.custom_fields, field)?.value_string;
    };

    return {
      isDesktop,
      imageWidth,
      imageHeight,
      media_path,
      media_file_name,
      linkData,
      getField
    };
  }
});
